import React, { useEffect, useState } from 'react';
import { Navbar, Container, Nav, Button, Form, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Swal from "sweetalert2";


const Dashboard = () => {
    const MySwal = Swal.mixin();

    const [sourceItemId, setSourceItemId] = useState('');
    const [targetItemId, setTargetItemId] = useState('');
    const [userInfo, setUserInfo] = useState({});

    const getUsername=()=>{
      return axios.get("/user/info").then(res=>{return res.data});
    }

    useEffect(async ()=>{
      let user =await getUsername();
      setUserInfo(user);
    },[])

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          MySwal.showLoading();
          const response = await axios.post('/update-fitments', {
              sourceItemId,
              targetItemId,
          });
          console.log(response.data);
          if(response.data && response.data?.Ack=="Success"){
            MySwal.fire({
              title: "Notice!",
              text: response.data.Ack,
              icon: "success",
              confirmButtonText: "Okay",
            });
          }
          else{
            let responseText="Token expired, please logout and log back in.";
            if(response.data?.Errors && response.data.Errors?.LongMessage){
              responseText=response.data.Errors?.LongMessage;
            }
            MySwal.fire({
              title: "Notice!",
              text: responseText,
              icon: "error",
              confirmButtonText: "Okay",
            });
          }
        } catch (error) {
        console.error('Error submitting form:', error);
          MySwal.fire({
            title: "Notice!",
            text: error,
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
    };

  return (
    <>
      <Container className="mt-4">
        <Navbar bg="light" expand="lg" className="justify-content-between">
            <Navbar.Brand>Dashboard</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    {/* Add other navigation links here if needed */}
                    </Nav>
                    <Nav>
                    <Dropdown>
                        <Dropdown.Toggle as={Navbar.Text} id="dropdown-basic">
                        <img
                            src="https://via.placeholder.com/30"
                            alt="User Avatar"
                            className="rounded-circle me-2"
                        />
                        {userInfo?.username || "User Name"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </Nav>
                </Navbar.Collapse>
        </Navbar>
        <div className="panel">
          <h2>Enter eBay Item IDs</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="sourceItemId">
              <Form.Label>Source Item ID</Form.Label>
              <Form.Control onChange={(e)=>{setSourceItemId(e.currentTarget.value)}} type="text" placeholder="Enter source item ID" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="targetItemId">
              <Form.Label>Target Item ID</Form.Label>
              <Form.Control  onChange={(e)=>{setTargetItemId(e.currentTarget.value)}} type="text" placeholder="Enter target item ID" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
